const auth = {
  isAuthenticated : false,
  authenticate() {
    this.isAuthenticated = true;
    localStorage.setItem('userAuthenticated', 'true');
  },
  checkAuthentication() {
    return localStorage.getItem('userAuthenticated') === 'true' ? this.isAuthenticated = true : this.isAuthenticated = false;
  },
  clearAuthentication() {
    this.isAuthenticated = false;
    localStorage.clear();
  }
};

export default auth;
