import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';

import http from '../../../util/http';

export default function Logs() {

    const [rows, setRows] = React.useState([]);

    useEffect(() => {
      async function fetchData() {
        try {
            const response =
                            await http.authorizedGet(process.env.REACT_APP_URL + "/traffic/greenLightLogs");
            if (response.status === 200) {
                let logData = response.body;
                setRows(logData);
            } else if (response.status === 400 || response.status === 401) {
                alert(response.message);
            } else {
                alert('Сървърна грешка. Съобщете на администратора на системата.');
            }
        } catch (error) {
            console.error(error.message);
        }
      }
      fetchData();
    }, []);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={8}
      />
    </div>
  );
}

const columns = [
    { field: 'username', headerName: 'Потребител', width: 230, valueGetter: (params) => {return params.getValue(params.id, "users").username;}},
    { field: 'date', headerName: 'Дата', width: 230, editable: false }
  ];
