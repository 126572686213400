import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';

import http from '../../../util/http';
import CustomModal from '../../customModal/CustomModal';

export default function Detectors(props) {

    const validateTime = (time) => {
      if (time !== 1 && time >= 0 && time <= 255) {
        return true;
      }
      return false;
    };

    const validateGap = (gap) => {
      const gapFloat = parseFloat(gap);
      if (gapFloat !== 1.0 && gapFloat >= 0 && gap <=25.5) {
        return true;
      }
      return false;
    };

    const columns = [
        { field: 'detector', headerName: 'Детектор', width: 230, editable: false},
        { field: 'gap', headerName: 'GAP', width: 130, editable: props.role  === 0 ? true : false, preProcessEditCellProps: (params) => { const isValid = validateGap(params.props.value); return { ...params.props, error: !isValid }; }, },
        { field: 'green', headerName: 't макс. зелено добав.', width: 200, editable: props.role  === 0 ? true : false, preProcessEditCellProps: (params) => { const isValid = validateTime(params.props.value); return { ...params.props, error: !isValid }; }, }
    ];

    const [rows, setRows] = React.useState([]);
    const [isChanged, setIsChanged] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
      async function fetchData() {
        try {
            const response = await http.authorizedGet(process.env.REACT_APP_URL + "/traffic/detectorsParameters");
            if (response.status === 200) {
                let formattedRows = formatRows(response.body);
                setRows(formattedRows);
            } else if (response.status === 400 || response.status === 401) {
                alert(response.message);
            } else {
                alert('Сървърна грешка. Съобщете на администратора на системата.');
            }
        } catch (error) {
            console.error(error);
        }
      }
      fetchData();
    }, []);

    const formatRows = (data) => {
        let tempRows = [];
        for (let i=0; i<data['gap'].length; i++) {
            let gapString = data['gap'][i].toString();
            tempRows.push({id: i, detector: 'Детектор ' + (i+1), gap: [gapString.slice(0,gapString.length-1), '.', gapString.slice(gapString.length-1,gapString.length)].join(''), green: data['green'][i]})
        }
        return tempRows;
    };
    
    const handleCellEditCommit = React.useCallback(
        ({ id, field, value }) => {
            const updatedRows = rows.map((row) => {
              if (row.id === id) {
                row[field] = value;
            }
              return row;
            });
            setRows(updatedRows);
            setIsChanged(true);
        },
        [rows],
      );
  
      const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
  
      const formatRowsForRequest = () => {
        let req_array_gap = [];
        let req_array_green = [];
        for (let i=0; i<rows.length; i++) {
            let gapFloat = parseFloat(rows[i].gap);
            let gapInt = parseInt(gapFloat * 10);
            req_array_gap.push(gapInt);
            req_array_green.push(rows[i].green);
        }
        const req_array = req_array_gap.concat(req_array_green);
        return req_array;
      };
  
      const sendDetectors = async () => {
        try {
          const detectors = formatRowsForRequest();
          const response = await http.authorizedPost(process.env.REACT_APP_URL + '/traffic/detectorsParameters').send({detectors: detectors});
          if (response.status === 200) {
             handleClose();
             alert('Успешно запазени параметри на детектори');
          }
      } catch (error) {
          console.error(error);
      }
      };

  return (
    <div>
        <Button variant="outlined" color="primary" disabled={!isChanged} onClick={handleOpen}>Запази</Button>
        <div style={{ height: 450, width: '100%' }}>
        <DataGrid
            rows={rows.slice(0, 20)}
            columns={columns}
            disableColumnMenu={true}
            onCellEditCommit={handleCellEditCommit}
            rowCount={20}
            hideFooter
        />
        <CustomModal open={open} text='Сигурни ли сте, че искате да запазите тези параметри на детектори?' execute={sendDetectors} close={handleClose} />
        </div>
    </div>
  );
}

