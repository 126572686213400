const OPERATING_MODES_MAPPER = {
    1 : [0x01, 0x01],
    2 : [0x01, 0x02],
    3 : [0x01, 0x03],
    4 : [0x01, 0x04],
    5 : [0x01, 0x05],
    6 : [0x01, 0x06],
    7 : [0x01, 0x07],
    8 : [0x01, 0x08],
    9 : [0x02, 0x02],
    10 : [0x03, 0x02],
    11 : [0x04, 0x02]
};

export function getOperatingMode(operatingMode) {
    let new_operating_mode_array = [];
    let arr = OPERATING_MODES_MAPPER[operatingMode];
    arr.forEach(byte => {
        new_operating_mode_array.push(byte);
    });
    return new_operating_mode_array;
}