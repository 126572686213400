import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import ErrorsTable from './ErrorsTable';
import PhasesTime from './PhasesTime';
import ControllerState from './ControllerState';
import GreenStreet from './GreenStreet';
import Logs from './Logs';
import Schedule from './Schedule';
import Detectors from './Detectors';
import MGUTimes from './MGUTimes';
import Inference from './Inference';
import CustomModal from '../../customModal/CustomModal';

import http from '../../../util/http';

import './Tombul.css';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundImage: 'url("/background2.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    top: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  }
}));

function Tombul(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resetController = async () => {
    try {
      const response = await http.authorizedPost(process.env.REACT_APP_URL + '/traffic/restart');
      if (response.status === 200) {
         handleClose();
         alert('Успешно рестартирахте контролера');
      }
  } catch (error) {
      console.error(error.message);
  }
  };

  

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List>
          { (props.data.role === 0 || props.data.role === 1) && <ListItem button key="Контролер" onClick={(event) => handleListItemClick(event, 4)} selected={selectedIndex === 4}>
            <ListItemText primary="Контролер" />
          </ListItem> }
          <ListItem button key="Зелена улица" onClick={(event) => handleListItemClick(event, 0)} selected={selectedIndex === 0}>
            <ListItemText primary="Зелена улица" />
          </ListItem>
          { (props.data.role === 0 || props.data.role === 1) && <ListItem button key="Рестарт" onClick={(event) => handleListItemClick(event, 1)} selected={selectedIndex === 1}>
            <ListItemText primary="Рестарт" />
          </ListItem> }
          { (props.data.role === 0 ) &&<ListItem button key="Откази" onClick={(event) => handleListItemClick(event, 2)} selected={selectedIndex === 2}>
            <ListItemText primary="Таблица с откази" />
          </ListItem> }
          { (props.data.role === 0 || props.data.role === 1) && <ListItem button key="Фази" onClick={(event) => handleListItemClick(event, 3)} selected={selectedIndex === 3}>
            <ListItemText primary="Фази" />
          </ListItem> }
          { (props.data.role === 0 || props.data.role === 1) && <ListItem button key="Отчети" onClick={(event) => handleListItemClick(event, 5)} selected={selectedIndex === 5}>
            <ListItemText primary="Отчети - Зелена улица" />
          </ListItem> }
          { (props.data.role === 0 || props.data.role === 1) && <ListItem button key="Часови график" onClick={(event) => handleListItemClick(event, 6)} selected={selectedIndex === 6}>
            <ListItemText primary="Часови график" />
          </ListItem> }
          { (props.data.role === 0 ) &&<ListItem button key="Детектори" onClick={(event) => handleListItemClick(event, 7)} selected={selectedIndex === 7}>
            <ListItemText primary="Детектори" />
          </ListItem> }
          { (props.data.role === 0 ) &&<ListItem button key="Времена МГУ" onClick={(event) => handleListItemClick(event, 8)} selected={selectedIndex === 8}>
            <ListItemText primary="Времена МГУ" />
          </ListItem> }
          { (props.data.role === 0 ) &&<ListItem button key="Разпознаване" onClick={(event) => handleListItemClick(event, 9)} selected={selectedIndex === 9}>
            <ListItemText primary="Разпознаване" />
          </ListItem> }
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
         <h3> Кръстовище - Томбул Джамия </h3>
        {selectedIndex === 0 && 
          <GreenStreet />
        }
        {selectedIndex === 1 && (props.data.role === 0 || props.data.role === 1) &&
          <div>
            <Button variant="contained" color="secondary" onClick={handleOpen}>
              Рестартирай контролера
            </Button>
            <CustomModal open={open} text='Сигурни ли сте, че искате да рестартирате контролера? Това ще доведе до изключване на светофара за около 2 минути.' execute={resetController} close={handleClose} />
          </div>
        }
        {selectedIndex === 2 &&
          <ErrorsTable />
        }
        {selectedIndex === 3 &&
          <PhasesTime role={props.data.role} />
        }
        {selectedIndex === 4 &&
          <ControllerState role={props.data.role} />
        }
        {selectedIndex === 5 &&
          <Logs />
        }
        {selectedIndex === 6 &&
          <Schedule role={props.data.role} />
        }
        {selectedIndex === 7 &&
          <Detectors role={props.data.role} />
        }
        {selectedIndex === 8 &&
          <MGUTimes role={props.data.role} />
        }
        {selectedIndex === 9 && (props.data.role === 0) &&
          <Inference role={props.data.role} />
        }
      </main>
    </div>
  );
}

export default Tombul;
