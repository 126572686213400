import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import http from '../../../util/http';
import moment from 'moment';

export default function VehicleCount() {

    const [rows, setRows] = React.useState([]);
    const [lane, setLane] = React.useState(1);
    const [date, setDate] = React.useState(moment(new Date()).format("yyyy-MM-DD"));

    async function fetchData() {
      try {
          const response =
                          await http.authorizedGet(process.env.REACT_APP_URL + "/traffic/getVehicleData/" + lane + '/' + date);
          if (response.status === 200) {
              let vehicleData = response.body.vehicleData;
              vehicleData.push({ "hour": 24, "count": 0});
              setRows(vehicleData);
          } else if (response.status === 400 || response.status === 401) {
              alert(response.message);
          } else {
              alert('Сървърна грешка. Съобщете на администратора на системата.');
          }
      } catch (error) {
          console.error(error.message);
      }
    }

    useEffect(() => {
      fetchData();
    }, []);

    useEffect(() => {
      fetchData();
    }, [lane, date]);

    const handleChange = (event) => {
      setLane(event.target.value);
    };

  return (
    <Paper>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={lane}
          label="Лента"
          onChange={handleChange}
        >
          <MenuItem value={1}>Лента 1</MenuItem>
          <MenuItem value={2}>Лента 2</MenuItem>
          <MenuItem value={3}>Лента 3</MenuItem>
          <MenuItem value={4}>Лента 4</MenuItem>
          <MenuItem value={5}>Лента 5</MenuItem>
          <MenuItem value={6}>Лента 6</MenuItem>
          <MenuItem value={7}>Лента 7</MenuItem>
          <MenuItem value={8}>Лента 8</MenuItem>
          <MenuItem value={9}>Лента 9</MenuItem>
          <MenuItem value={10}>Лента 10</MenuItem>
        </Select>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Дата"
            inputFormat="yyyy-MM-dd"
            format="yyyy-MM-DD"
            value={date}
            onChange={(date) => setDate(moment(date).format('yyyy-MM-DD'))}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Chart
            data={rows}
        >
        <ArgumentAxis />
        <ValueAxis />

        <BarSeries
            valueField="count"
            argumentField="hour"
        />
        <Title text="Брой превозни средства по часове" />
        <Animation />
        </Chart>
    </Paper>
  );
}

