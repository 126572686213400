import React from 'react';
import './Login.css'
import request from 'superagent';
import auth from '../../util/auth';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { withNotification } from '../notification/Notification';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.loginPressed = this.loginPressed.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.goBack = this.goBack.bind(this);

        this.state = {
            username: '',
            password: ''
        }
    }

    goBack() {
        this.props.history.push('/');
    }

    async loginPressed(event) {
        event.preventDefault();
        const username = this.state.username;
        const password = this.state.password;

        try {
            const response =
                            await request
                              .post(process.env.REACT_APP_URL + "/auth/login")
                              .withCredentials()
                              .send({'username': username, 'password': password})
                              .set('accept', 'json');
            if (response.status === 200) {
              auth.authenticate();
              this.props.history.push('/app');
            }
        } catch (error) {
            console.error(error.message);
            this.props.setNotificationMessage('Грешно потребителско име или парола');
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value})
    }

    render() {
        return (
            <div className='Login'>
              <Typography variant="h4">Добре дошли!</Typography>
              <form>
                  <div>
                    <TextField
                       fullWidth
                       required
                       value={this.state.username}
                       onChange={this.handleChange}
                       name="username"
                       margin="dense"
                       label="Потребителско име"
                       type="text"
                     />
                  </div>
                  <div>
                    <TextField
                       fullWidth
                       required
                       value={this.state.password}
                       onChange={this.handleChange}
                       name="password"
                       margin="dense"
                       label="Парола"
                       type="password"
                     />
                  </div>
                  <Button fullWidth margin="dense" type="submit" variant="contained" color="primary" disabled={!this.state.username || !this.state.password} onClick={this.loginPressed}>
                    Влез
                  </Button>
              </form>
            </div>
        );
    }
}

export default withNotification(Login);
