import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { Select, MenuItem, InputLabel, FormControl} from '@material-ui/core';

import http from '../../util/http';
import { withNotification } from '../notification/Notification';

class AddUser extends Component {
    static propTypes = {
      open: PropTypes.bool,
      close: PropTypes.func
    }
    constructor(props) {
      super(props);
      this.state = {
        username: '',
        password: '',
        role: 2,
        error: '',
        success: ''
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }

    handleChange(event) {
      const name = event.target.name;
      this.setState({
        [name]: event.target.value,
        error: ''
      });
    }

    handleClose() {
      this.setState({
          username: '',
          password: '',
          role: 2
      });
      this.props.close();
    }

    async handleSubmit(event) {
      event.preventDefault();
      try {
        const response =
                        await http.authorizedPost(process.env.REACT_APP_URL + '/auth/create-account')
                          .send({'user': {'username': this.state.username, 'password': this.state.password, 'role': this.state.role}});
        if (response.status === 200) {
            this.props.setNotificationMessage(`Потребителят "${this.state.username}" е създаден успешно!`);
            this.props.handleClose();
        } else {
            this.props.setNotificationMessage(`Потребителят не може да се създаде: ${response.body.message}`);
            this.props.handleClose();
        }
      } catch (err) {
        if (err.status > 299 || err.status < 200) {
          if (err.response.body.message === 'username_exists') {
            this.props.setNotificationMessage(`Вече съществува акаунт с това потребителско име`);
          } else {
            this.setState({
                error: err.response.body.message,
                success: ''
            });
          }
        }
      }
    }

    render() {
      return (
        <Dialog
            open={this.props.open}
            onClose={this.handleClose}
        >
        <DialogContent>
        <form id="addUserForm" onSubmit={this.handleSubmit}>
            <TextField
              autoFocus
              fullWidth
              required
              value={this.state.username}
              onChange={this.handleChange}
              name="username"
              margin="dense"
              label="Потребителско име"
              type="username"
            />
            <TextField
              fullWidth
              required
              value={this.state.password}
              onChange={this.handleChange}
              name="password"
              margin="dense"
              label="Парола"
              type="password"
            />
            <FormControl>
                <InputLabel id="roleLabel">Роля</InputLabel>
                <Select
                name='role'
                value={this.state.role}
                onChange={this.handleChange}
                autoWidth
                labelId='roleLabel'
                label="Роля"
                >
                <MenuItem value={0}>Администратор</MenuItem>
                <MenuItem value={1}>Община</MenuItem>
                <MenuItem value={2}>Зелена улица</MenuItem>
                </Select>
            </FormControl>
            <Typography color="error" >
                {this.state.error}
            </Typography>
        </form>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disabled={!this.state.username || !this.state.password || this.state.role === null || this.state.role === undefined } onClick={this.handleSubmit}>
            Създай
          </Button>
          <Button variant="outlined" color="primary" onClick={this.handleClose}>
            Затвори
          </Button>
        </DialogActions>
        </Dialog>
      );
    }
}

export default withNotification(AddUser);
