import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';

import http from '../../../util/http';
import CustomModal from '../../customModal/CustomModal';

import { MTK_TIMES_MAPPER } from '../../../util/constants';

export default function MGUTimes(props) {

    const [rows, setRows] = React.useState([]);
    const [isChanged, setIsChanged] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const validateTime = (time) => {
      if (time >= 8 && time <= 50) {
        return true;
      }
      return false;
    };

    const columns = [
      { field: 'agpg', headerName: ' ', width: 170, editable: false, sortable: false, filterable: false},
      { field: 'minsp1', headerName: 'СП1 Мин', width: 120, editable: props.role  === 0 ? true : false, sortable: false, filterable: false, align: "center", headerAlign: "center", preProcessEditCellProps: (params) => { const isValid = validateTime(params.props.value); return { ...params.props, error: !isValid }; },},
      { field: 'maxsp1', headerName: 'СП1 Макс', width: 120, editable: props.role  === 0 ? true : false, sortable: false, filterable: false, align: "center", headerAlign: "center", preProcessEditCellProps: (params) => { const isValid = validateTime(params.props.value); return { ...params.props, error: !isValid }; }, },
      { field: 'minsp2', headerName: 'СП2 Мин', width: 120, editable: props.role  === 0 ? true : false, sortable: false, filterable: false, align: "center", headerAlign: "center", preProcessEditCellProps: (params) => { const isValid = validateTime(params.props.value); return { ...params.props, error: !isValid }; }, },
      { field: 'maxsp2', headerName: 'СП2 Макс', width: 120, editable: props.role  === 0 ? true : false, sortable: false, filterable: false, align: "center", headerAlign: "center", preProcessEditCellProps: (params) => { const isValid = validateTime(params.props.value); return { ...params.props, error: !isValid }; }, },
      { field: 'minsp3', headerName: 'СП3 Мин', width: 120, editable: props.role  === 0 ? true : false, sortable: false, filterable: false, align: "center", headerAlign: "center", preProcessEditCellProps: (params) => { const isValid = validateTime(params.props.value); return { ...params.props, error: !isValid }; }, },
      { field: 'maxsp3', headerName: 'СП3 Макс', width: 120, editable: props.role  === 0 ? true : false, sortable: false, filterable: false, align: "center", headerAlign: "center", preProcessEditCellProps: (params) => { const isValid = validateTime(params.props.value); return { ...params.props, error: !isValid }; }, },
      { field: 'minsp4', headerName: 'СП4 Мин', width: 120, editable: props.role  === 0 ? true : false, sortable: false, filterable: false, align: "center", headerAlign: "center", preProcessEditCellProps: (params) => { const isValid = validateTime(params.props.value); return { ...params.props, error: !isValid }; }, },
      { field: 'maxsp4', headerName: 'СП4 Макс', width: 120, editable: props.role  === 0 ? true : false, sortable: false, filterable: false, align: "center", headerAlign: "center", preProcessEditCellProps: (params) => { const isValid = validateTime(params.props.value); return { ...params.props, error: !isValid }; }, },
      { field: 'minsp5', headerName: 'СП5 Мин', width: 120, editable: props.role  === 0 ? true : false, sortable: false, filterable: false, align: "center", headerAlign: "center", preProcessEditCellProps: (params) => { const isValid = validateTime(params.props.value); return { ...params.props, error: !isValid }; }, },
      { field: 'maxsp5', headerName: 'СП5 Макс', width: 120, editable: props.role  === 0 ? true : false, sortable: false, filterable: false, align: "center", headerAlign: "center", preProcessEditCellProps: (params) => { const isValid = validateTime(params.props.value); return { ...params.props, error: !isValid }; }, }
  ];

    useEffect(() => {
      async function fetchData() {
        try {
            const response =
                            await http.authorizedGet(process.env.REACT_APP_URL + "/traffic/mguTimes");
            if (response.status === 200) {
                let formattedRows = formatRows(response.body);
                setRows(formattedRows);
            } else if (response.status === 400 || response.status === 401) {
                alert(response.message);
            } else {
                alert('Сървърна грешка. Съобщете на администратора на системата.');
            }
        } catch (error) {
            console.error(error.message);
        }
      }
      fetchData();
    }, []);

    const formatRows = (data) => {
        let dataArray = [];
        for (const [key, value] of Object.entries(data)) {
          let dataRow = {};
          dataRow['agpg'] = MTK_TIMES_MAPPER[key];
          dataRow['id'] = key;
          for (let i = 0; i< 5; i++) {
            dataRow['minsp' + (i+1).toString()] = value.min[i];
            dataRow['maxsp' + (i+1).toString()] = value.max[i];
          }
          dataArray.push(dataRow);
        }
        return dataArray;
    };

    const handleCellEditCommit = React.useCallback(
      ({ id, field, value }) => {
          const updatedRows = rows.map((row) => {
            if (row.id === id) {
              row[field] = parseInt(value);
          }
            return row;
          });
          setRows(updatedRows);
          setIsChanged(true);
      },
      [rows],
    );

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const formatRowsForRequest = () => {
      let req_array = [];
      for (let i = 1; i<=5; i++) {
        let min_array = [];
        let max_array = [];
        for (let j=0; j<rows.length; j++) {
          min_array.push(rows[j]['minsp' + i.toString()]);
          max_array.push(rows[j]['maxsp' + i.toString()]);
        }
        req_array = req_array.concat(min_array);
        req_array = req_array.concat(max_array);  
      }
      return req_array;
    };

    const sendMguTimes = async () => {
      try {
        const mguTimes = formatRowsForRequest();
        const response = await http.authorizedPost(process.env.REACT_APP_URL + '/traffic/mguTimes').send({mguTimes: mguTimes});
        if (response.status === 200) {
           handleClose();
           alert('Успешно запазен часови график');
        }
    } catch (error) {
        console.error(error.message);
    }
    };

  return (
    <div>
      { props.role === 0 && <Button variant="outlined" color="primary" disabled={!isChanged} onClick={handleOpen}>Запази</Button> }
      <div style={{ height: 560, width: '100%', textAlign: "center" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu={true}
          onCellEditCommit={handleCellEditCommit}
          hideFooter
        />
        <CustomModal open={open} text='Сигурни ли сте, че искате да запазите това?' execute={sendMguTimes} close={handleClose} />
      </div>
    </div>
  );
}
