import React, { useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import VideoStream from './VideoStream';

const Inference = () => {
  
  const makeInferenceURL = (camera) => {
    return `${process.env.REACT_APP_URL}/hls/${camera}/stream.m3u8`;
  };

  const k1URL = makeInferenceURL('k1');
  const k3URL = makeInferenceURL('k3');
  const k5URL = makeInferenceURL('k5');
  const k7URL = makeInferenceURL('k7');

  return (
    <Grid container spacing={2} >
      <Grid item xs={6}>
        <VideoStream url={k1URL}/>
      </Grid>
      <Grid item xs={6}>
        <VideoStream url={k3URL}/>
      </Grid>
      <Grid item xs={6}>
        <VideoStream url={k5URL}/>
      </Grid>
      <Grid item xs={6}>
        <VideoStream url={k7URL}/>
      </Grid>
    </Grid>
  );
};

export default Inference;
