import React, { useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TrafficLight from '../../TrafficLight/TrafficLight';
import PedestrianTL from './PedestrianTL';
import DuckTL from './DuckTL';
import { Typography, Select, MenuItem, Button, TextField, InputLabel, FormControl } from '@material-ui/core';

import VehicleCount from './VehicleCount.js';
import http from '../../../util/http';
import CustomModal from '../../customModal/CustomModal';
import { getOperatingMode } from '../../../util/mtkUtils';

const default_data = {
    'operating_mode': 0,
    'sp' : 0,
    'current_phase': 0,
    'auto_groups': ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    'pedestrian_groups': ['', '', '', '', '', '', '', ''],
    'ducks': [0,0,0,0,0,0,0,0],
    'fatal_refusal': null,
    'argument': null,
    'phase_green_street': null,
    'seconds_to_green_street': null,
    'day' : '',
    'time' : ''
};

function mapOperatingMode(number) {
    switch (number) {
        case 1:
            return 'Твърд цикъл';
        case 2:
            return 'Жълта мигаща светлина';
        case 3:
            return 'Всичко тъмно';
        case 4:
            return 'Местно гъвкаво управление';
        case 5:
            return 'Преход към координиран режим';
        case 6:
            return 'Координиран режим';
        case 7:
            return 'Ръчно управление';
        case 8:
            return 'Зелена улица';
        case 9:
            return 'Всичко червено';
        default:
            return 'Няма информация';
    }
}

export default function ControllerState(props) {

    const [data, setData] = React.useState(default_data);
    const [page, setPage] = React.useState(0);
    const [operatingMode, setOperatingMode] = React.useState(0);
    const [timeOperatingMode, setTimeOperatingMode] = React.useState('');
    const [isChanged, setIsChanged] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [stateInterval, setStateInterval] = React.useState(1);

    useEffect(() => {
        fetchData();
        fetchStateInterval();
        const intervalCall = setInterval(() => {
            fetchData();
        }, 2000);
        return () => { 
          clearInterval(intervalCall);
        };
    }, []);

    async function fetchData() {
        try {
            const response = await http.authorizedGet(process.env.REACT_APP_URL + "/traffic/controllerState");
            if (response.status === 200) {
                setData(response.body);
            } else if (response.status === 400 || response.status === 401) {
                alert(response.message);
            } else {
                alert('Сървърна грешка. Съобщете на администратора на системата.');
            }
        } catch (error) {
            console.error(error.message);
        }
    }

    // MTK send state interval
    async function updateStateInterval() {
        try {
            const response = await http.authorizedPost(process.env.REACT_APP_URL + '/traffic/sendStateInterval')
                                .send({ interval: stateInterval });

            if (response.status === 200) {
                alert(`МТК то вече ще изпраща състоянието си на всеки ${stateInterval}`);
            } else if (response.status === 400 || response.status === 401) {
                alert(response.message);
            } else {
                alert('Сървърна грешка. Съобщете на администратора на системата.');
            }
        } catch (error) {
            console.error(error.message);
        }
    }

    async function fetchStateInterval() {
        try {
            const response =
                            await http.authorizedGet(process.env.REACT_APP_URL + "/traffic/sendStateInterval");
            if (response.status === 200) {
                setStateInterval(response.body.interval);
            } else if (response.status === 400 || response.status === 401) {
                alert(response.message);
            } else {
                alert('Сървърна грешка. Съобщете на администратора на системата.');
            }
        } catch (error) {
            console.error(error.message);
        }
    }

    function stateIntervalIsValid() {
        return (1 <= stateInterval && stateInterval <= 255);
    }

    function stateIntervalIsInvalidMessage() {
        if (stateIntervalIsValid() === false) {
            return 'Стойността не е от 1 до 255.';
        }
        return null;
    }

    useEffect(() => {
        const validateInput = () => {
            const operatingModeIsChanged = (timeOperatingMode !== '' && operatingMode !== 0) || operatingMode === 11;
            setIsChanged(operatingModeIsChanged);
        }

        validateInput();
        if (operatingMode === 11) {
            setTimeOperatingMode('');
        }
    }, [operatingMode, timeOperatingMode])

    const handleChange = (event, newPage) => {
        setPage(newPage);
      };

    const handleChangeOperatingMode = (event, newOperatingMode) => {
        setOperatingMode(newOperatingMode.props.value);
    };

    const handleChangeTime = (event) => {
        const onlyNumsTime = event.target.value.replace(/[^0-9]/g, '');
        if ((onlyNumsTime < 255 && onlyNumsTime >= 0) || onlyNumsTime === '') {
            setTimeOperatingMode(onlyNumsTime);
        }
    };

    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };


    const saveOperatingMode = async () => {
        try {
          let result = getOperatingMode(operatingMode);
          if (operatingMode === 11) {
            result.push('1');
          }
          else {
            result.push(timeOperatingMode.toString(16));
          }
          const response = await http.authorizedPost(process.env.REACT_APP_URL + '/traffic/setOperatingMode').send({operatingMode: result});
          if (response.status === 200) {
             setOperatingMode(0);
             setTimeOperatingMode('');
             handleClose();
             alert('Успешно запазен режим на работа');
          }
      } catch (error) {
          console.error(error);
      }
      };

  return (
    <div style={{ width: '100%', fontSize: '18px' }}>
        <Tabs value={page} onChange={handleChange} centered variant="fullWidth">
            <Tab label="Състояние на контролера" />
            {props.role === 0 && <Tab label="Брояч" />}
        </Tabs>
        { page === 0 &&
        <div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', flexWrap: 'wrap'}} >
            <div style={{width: '40%'}}>
                <p> {data.day} {data.time} </p>
                { props.role === 0 &&
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                        <TextField id="state-interval" type="number" label="Интервал за изпращане на статус на МТК(секунди)" style={{ width: '100%' }} value={stateInterval} 
                            error={stateIntervalIsValid() === false}
                            helperText={stateIntervalIsInvalidMessage()}
                            onChange={(event) => {
                                setStateInterval(event.target.value);
                            }}
                        />
                        <Button variant="contained" color="primary" onClick={updateStateInterval} 
                            disabled={stateIntervalIsValid() === false} >
                            Промени
                        </Button>
                    </div>
                }
                <p>Режим на работа: {mapOperatingMode(data.operating_mode)}</p>
                { props.role === 0 && 
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                        <FormControl style={{minWidth: '40%'}}>
                            <InputLabel id="label">Промяна режим на работа</InputLabel>
                            <Select
                                value={operatingMode}
                                onChange={handleChangeOperatingMode}
                                autoWidth
                                labelId='label'
                                label="Промяна режим на работа"
                            >
                                <MenuItem value={1}>ТЦ: СП1</MenuItem>
                                <MenuItem value={2}>ТЦ: СП2</MenuItem>
                                <MenuItem value={3}>ТЦ: СП3</MenuItem>
                                <MenuItem value={4}>ТЦ: СП4</MenuItem>
                                <MenuItem value={5}>ТЦ: СП5</MenuItem>
                                <MenuItem value={6}>ТЦ: СП6</MenuItem>
                                <MenuItem value={7}>ТЦ: СП7</MenuItem>
                                <MenuItem value={8}>ТЦ: СП8</MenuItem>
                                <MenuItem value={9}>ЖМС</MenuItem>
                                <MenuItem value={10}>ВТ</MenuItem>
                                <MenuItem value={11}>Локален режим</MenuItem>
                            </Select>
                        </FormControl>
                        { operatingMode !== 11 &&
                        <TextField
                            id="outlined-number"
                            autoWidth
                            label="Време за работа (минути)"
                            type="number"
                            onChange={handleChangeTime}
                            value={timeOperatingMode}
                            style={{width: '43%'}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        /> }
                        <Button variant="outlined" color="primary" disabled={!isChanged} onClick={handleOpen}>Запази</Button>
                        <CustomModal open={open} text='Сигурни ли сте, че искате да промените режима на работа?' execute={saveOperatingMode} close={handleClose} />
                    </div>
                }
                {
                    timeOperatingMode === '0' && 
                    <p style={{color: 'red'}}>При зададено време за работа 0 минути, режимът ще се изпълнява безкрайно!</p>
                }
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                    <p>Сигнална програма: {data.sp}</p>
                    <p style={{marginLeft: '20px'}}>Текущо изпълнявана фаза: {data.current_phase}</p>
                </div>
            </div>
            <div>
                { data.phase_green_street && <p> Заявена фаза за "Зелена улица": {data.phase_green_street} </p> }
                { data.seconds_to_green_street && <p> Време до изпълнение: {data.seconds_to_green_street} секунди</p> }
                { data.fatal_refusal && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p>Фатален отказ: {data.fatal_refusal}</p>
                    <p>Аргумент: {data.argument}</p>
                </div>
                }
            </div>
        </div>
        <TableContainer component={Paper}>
            <Typography variant='h5' align='center' style={{marginTop: '20px'}}> Автомобилни групи </Typography>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    {Array.from({length: data.auto_groups.length}, (_, i) => i + 1).map((index) => (
                        <TableCell scope="row" align='center'>
                            {index}
                        </TableCell>
                    ))}
                </TableHead>
                <TableBody>
                <TableRow>
                    {data.auto_groups.map((row, i) => (
                        <TableCell align='center'>
                            <TrafficLight GreenOn={row.includes('g')} YellowOn={row.includes('y')} RedOn={row.includes('r')} Size={30} />
                        </TableCell>
                    ))}
                </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <TableContainer component={Paper}>
        <Typography variant='h5' align='center' style={{marginTop: '20px'}}> Пешеходни групи </Typography>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    {Array.from({length: data.pedestrian_groups.length}, (_, i) => i + 1).map((index) => (
                        <TableCell scope="row" align='center'>
                            {index}
                        </TableCell>
                    ))}
                </TableHead>
                <TableBody>
                <TableRow>
                    {data.pedestrian_groups.map((row, i) => (
                        <TableCell align='center'>
                            <PedestrianTL GreenOn={row === 'g' ? true : false} RedOn={row === 'r' ? true : false} />
                        </TableCell>
                    ))}
                </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <TableContainer component={Paper}>
        <Typography variant='h5' align='center' style={{marginTop: '20px'}}> Допълнителни секции </Typography>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    {Array.from({length: data.ducks.length}, (_, i) => i + 1).map((index) => (
                        <TableCell scope="row" align='center'>
                            {index}
                        </TableCell>
                    ))}
                </TableHead>
                <TableBody>
                <TableRow>
                    {data.ducks.map((row, i) => (
                        <TableCell align='center'>
                            <DuckTL GreenOn={row ? true : false} />
                        </TableCell>
                    ))}
                </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        </div>
        }
        { page === 1 && <VehicleCount/> }
    </div>
  );
}
