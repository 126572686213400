import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Paper, MenuList, MenuItem} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import ChangePassword from '../changePassword/ChangePassword';
import AddUser from '../addUser/AddUser';

import http from '../../util/http';
import auth from '../../util/auth';

class Navigation extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    role: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.location.pathname,
      isDropdownOpen: false,
      isPasswordChangeOpen: false,
      username: '',
    };

    this.onItemClick = this.onItemClick.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.logout = this.logout.bind(this);
    this.openChangePassword = this.openChangePassword.bind(this);
    this.closeChangePassword = this.closeChangePassword.bind(this);
    this.openAddUser = this.openAddUser.bind(this);
    this.closeAddUser = this.closeAddUser.bind(this);
  }

  async componentWillMount() {
    try {
        const response =
                        await http.authorizedGet(process.env.REACT_APP_URL + "/auth/me");
        if (response.status === 200) {
            this.setState({username: response.body.user.username});
        } else if (response.status === 400 || response.status === 401) {
            alert(response.message);
        } else {
            alert('Сървърна грешка. Съобщете на администратора на системата.');
        }
    } catch (error) {
        console.error(error.message);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({selected: this.props.location.pathname});
    }
  }

  toggleMenu() {
    this.setState({
        isDropdownOpen: !this.state.isDropdownOpen,
    });
  }

  onItemClick(e) {
    e.preventDefault();
    const link = e.target.getAttribute('data');
    if (this.state.selected !== link) {
      this.props.history.push(link);
    }
  }

  openChangePassword() {
    this.setState({isPasswordChangeOpen: true});
  }

  closeChangePassword() {
    this.setState({isPasswordChangeOpen: false});
  }

  openAddUser() {
    this.setState({isAddUserOpen: true});
  }

  closeAddUser() {
    this.setState({isAddUserOpen: false});
  }

  async logout() {
    try {
      const response =
                      await http.authorizedPost(process.env.REACT_APP_URL + "/auth/logout");
      if (response.status === 200) {
        auth.clearAuthentication();
        this.props.history.push('/login');
      } else if (response.status === 400 || response.status === 401) {
          alert(response.message);
      } else {
          alert('Сървърна грешка. Съобщете на администратора на системата.');
      }
  } catch (error) {
      console.error(error.message);
  }
  }

  render() {
    return (
        <Paper style={{zIndex: '100'}}>
          <Box
            boxShadow={2}
            bgcolor="background.paper"
            m={1}
            p={1}
          >
            <MenuList style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                <MenuItem onClick={this.toggleMenu} name='crossroads' >
                    Кръстовища
                    {
                    !this.state.isDropdownOpen &&
                    <ArrowRightIcon />
                    }
                    {
                    this.state.isDropdownOpen &&
                    <ArrowDownIcon />
                    }
                </MenuItem>
                {
                    this.state.isDropdownOpen &&
                    <MenuList>
                        <MenuItem name='tombul' data='/app/tombul' onClick={this.onItemClick}>
                            Томбул Джамия
                        </MenuItem>
                        <MenuItem name='tombul' data='/app/tombul' onClick={this.onItemClick} disabled>
                            Оборище
                        </MenuItem>
                    </MenuList>
                }
                </div>
                <div style={{display: 'flex'}}>
                  { this.props.role === 0 &&
                    <MenuItem>  <Link onClick={this.openAddUser}>Добавяне на акаунт</Link> </MenuItem>
                  }
                  <MenuItem> <LockOpenIcon   /> <Link onClick={this.openChangePassword}> Смяна на парола</Link> </MenuItem>
                  <MenuItem> <ExitToAppIcon /> <Link onClick={this.logout}> Излизане</Link> </MenuItem>
                </div>
            </MenuList>
            <ChangePassword open={this.state.isPasswordChangeOpen} close={this.closeChangePassword} username={this.state.username} />
            { this.props.role === 0 &&
              <AddUser open={this.state.isAddUserOpen} close={this.closeAddUser}/>
            }
          </Box>
        </Paper>
    );
  }
}

export default Navigation;
