/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

const scale = 1 / 0.5;

const PedestrianTL = ({
  onRedClick,
  onGreenClick,
  RedOn,
  GreenOn,
  Size,
  BlackColor,
  DisabledColor,
  RedColor,
  GreenColor,
  Horizontal,
  ...props
}) => (
  <svg
    width={`${Size * (Horizontal ? scale : 1)}px`}
    height={`${Size * (Horizontal ? 1 : scale)}px`}
    viewBox={Horizontal ? "0 0 30 30" : "0 0 60 40"}
    version="1.1"
    {...props}
  >
    <defs>
      <circle
        style={{ cursor: onGreenClick ? "pointer" : undefined }}
        id="greenCirclePathD"
        cx="30"
        cy="30"
        r="20"
      />

      <filter
        x="-50%"
        y="-50%"
        width="200%"
        height="200%"
        filterUnits="objectBoundingBox"
        id="shadowFilter"
      >
        <feGaussianBlur
          stdDeviation="3"
          in="SourceAlpha"
          result="shadowBlurInner1"
        />
        <feOffset
          dx="0"
          dy="4"
          in="shadowBlurInner1"
          result="shadowOffsetInner1"
        />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
          type="matrix"
          in="shadowInnerInner1"
        />
      </filter>
    </defs>
    <g transform={Horizontal ? `rotate(-90 30 30)` : undefined}>
      <rect fill={BlackColor} x="0" y="0" width="60" height="60" rx="8" />

      <use
        fill={GreenOn ? GreenColor : DisabledColor}
        fillRule="evenodd"
        xlinkHref="#greenCirclePathD"
      />
      <use
        onClick={onGreenClick}
        fill="black"
        fillOpacity="1"
        filter="url(#shadowFilter)"
        xlinkHref="#greenCirclePathD"
      />
    </g>
  </svg>
);

PedestrianTL.propTypes = {
  onRedClick: PropTypes.func,
  onGreenClick: PropTypes.func,
  RedOn: PropTypes.bool,
  GreenOn: PropTypes.bool,
  Size: PropTypes.number,
  BlackColor: PropTypes.string,
  DisabledColor: PropTypes.string,
  RedColor: PropTypes.string,
  GreenColor: PropTypes.string,
  Horizontal: PropTypes.bool
};

PedestrianTL.defaultProps = {
  onRedClick: undefined,
  onGreenClick: undefined,
  RedOn: false,
  GreenOn: false,
  Size: 30,
  BlackColor: "#000000",
  DisabledColor: "#4A4A4A",
  RedColor: "#D0021B",
  GreenColor: "#7ED321",
  Horizontal: false
};

export default PedestrianTL;