import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import http from '../../../util/http';


export default function GreenStreet() {

  const [phase, setPhase] = React.useState("");
  const [duration, setDuration] = React.useState();
  const [start, setStart] = React.useState();
  const [greenStreetStatus, setGreenStreetStatus] = React.useState({});

  useEffect(() => {
    getGreenStreetStatus();
    const intervalCall = setInterval(() => {
      getGreenStreetStatus();
    }, 5000);
    return () => {
      clearInterval(intervalCall);
    };
  }, []);

  async function getGreenStreetStatus() {
    try {
      const response =
        await http.authorizedGet(process.env.REACT_APP_URL + "/traffic/greenStreetStatus");
      if (response.status === 200) {
        setGreenStreetStatus(response.body);
      } else if (response.status === 400 || response.status === 401) {
        alert(response.message);
      } else {
        alert('Сървърна грешка. Съобщете на администратора на системата.');
      }
    } catch (error) {
      console.log(error.message);
      console.error(error.message);
    }
  }

  const startGreenLight = async () => {
    try {
      const response = await http.authorizedPost(process.env.REACT_APP_URL + '/traffic/greenlight').send({ phase: phase, duration: duration, seconds_to_start: start });
      if (response.status === 200) {
        alert('Успешно задаване на зелена улица');
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const cancelGreenLight = async () => {
    try {
      const response = await http.authorizedPost(process.env.REACT_APP_URL + '/traffic/cancelGreenLight');
      if (response.status === 200) {
        alert('Успешно спиране на зелена улица');
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleChangePhase = (event) => {
    setPhase(event.target.value);
  };

  const handleChangeDuration = (event) => {
    setDuration(event.target.value);
  };

  const handleChangeStart = (event) => {
    setStart(event.target.value);
  };

  return (
    <div className="greenlight">
      {!phase && <img src="/tombul-cover.png" className="tb-cover" alt="crossroad map" />}
      {phase === 4 && <img src="/tombul-cover-a.png" className="tb-cover" alt="crossroad map phase 4" />}
      {phase === 5 && <img src="/tombul-cover-b.png" className="tb-cover" alt="crossroad map phase 5" />}
      {phase === 6 && <img src="/tombul-cover-c.png" className="tb-cover" alt="crossroad map phase 6" />}
      {phase === 7 && <img src="/tombul-cover-d.png" className="tb-cover" alt="crossroad map phase 7" />}
      <div className="inputs">
        <div>
          <InputLabel id="phase" >Специална Фаза</InputLabel>
          <Select
            labelId="phase"
            id="phase"
            value={phase}
            onChange={handleChangePhase}
            style={{
              minWidth: '100px'
            }}
          >
            <MenuItem value={4}>А</MenuItem>
            <MenuItem value={5}>Б</MenuItem>
            <MenuItem value={6}>В</MenuItem>
            <MenuItem value={7}>Г</MenuItem>
          </Select>
        </div>
        <TextField required id="duration" label="Продължителност в секунди" style={{ width: '30%' }} value={duration} onChange={handleChangeDuration} />
        <TextField required id="start" label="Време на започване в секунди" style={{
          minWidth: '280px'
        }} value={start} onChange={handleChangeStart} />
        <Button variant="contained" color="primary" onClick={startGreenLight}>
          Продължи
        </Button>
      </div>
      {!greenStreetStatus.isExecutingGreenStreet && <Typography> Няма зададена зелена улица. </Typography>}
      {greenStreetStatus.isExecutingGreenStreet && greenStreetStatus.secondsToStart &&
        <Typography> Текущо зададена зелена улица: фаза {greenStreetStatus.requestedPhase}. Секунди до започване: {greenStreetStatus.secondsToStart} </Typography>
      }

      {greenStreetStatus.isExecutingGreenStreet && greenStreetStatus.secondsToStart === null &&
        <Typography> В момента се изпълнява зелена улица: фаза {greenStreetStatus.requestedPhase} </Typography>
      }
      {greenStreetStatus.isExecutingGreenStreet &&
        <Button variant="contained" color="secondary" onClick={cancelGreenLight} style={{ marginTop: '20px', width: '40% ' }}>
          Спри зелена улица
        </Button>
      }
    </div>
  );
}