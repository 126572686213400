import React from 'react';
import { Redirect } from "react-router-dom";

import PrivateRoute from '../privateRoute/PrivateRoute';

import Navigation from '../navigation/Navigation';
import Tombul from '../crossroads/tombul/Tombul';

import http from '../../util/http';

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: 0,
    };
  }

  async componentDidMount() {
    try {
        const response =
                        await http.authorizedGet(process.env.REACT_APP_URL + "/auth/me");
        if (response.status === 200) {
            this.setState({userRole: response.body.user.role});
        }
    } catch (error) {
        console.error(error.message);
    }
  }

    render() {
        const authState = localStorage.getItem('userAuthenticated')
      
        if (authState === null || authState === 'false' || authState === undefined) {
          return <Redirect to='login' />
        }
        return (
            <div className='container'>
              <Navigation location={this.props.location} history={this.props.history} role={this.state.userRole} />
              <div className="pageContent">
                <PrivateRoute exact path="/app/tombul" {...this.props} role={this.state.userRole} component={Tombul} />
              </div>
            </div>
        );
    }
}