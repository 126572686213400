export const MTK_TIMES_MAPPER = {
    'ag1' : 'Автомобилна група 1',
    'ag2' : 'Автомобилна група 2',
    'ag3' : 'Автомобилна група 3',
    'ag4' : 'Автомобилна група 4',
    'ag5' : 'Автомобилна група 5',
    'ag6' : 'Автомобилна група 6',
    'ag7' : 'Автомобилна група 7',
    'ag8' : 'Автомобилна група 8',
    'ag9' : 'Автомобилна група 9',
    'ag10' : 'Автомобилна група 10',
    'ag11' : 'Автомобилна група 11',
    'ag12' : 'Автомобилна група 12',
    'ag13' : 'Автомобилна група 13',
    'ag14' : 'Автомобилна група 14',
    'ag15' : 'Автомобилна група 15',
    'ag16' : 'Автомобилна група 16',
    'pg1' : 'Пешеходна група 1',
    'pg2' : 'Пешеходна група 2',
    'pg3' : 'Пешеходна група 3',
    'pg4' : 'Пешеходна група 4',
    'pg5' : 'Пешеходна група 5',
    'pg6' : 'Пешеходна група 6',
    'pg7' : 'Пешеходна група 7',
    'pg8' : 'Пешеходна група 8'
};
