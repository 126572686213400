import React, {Component} from 'react';
import { Route, Redirect } from 'react-router';
import PropTypes from 'prop-types';

import auth from '../../util/auth';

export default class PrivateRoute extends Component {
  static propTypes = {
    component: PropTypes.func.isRequired
  }

  render() {
    const {component: Component, ...rest} = this.props;
    const renderRoute = (props, rest) => {
      const isAuthenticated = auth.checkAuthentication();
      if (isAuthenticated) {
        return (
          <Component {...props} data={rest} />
        );
      }

      const to = {
        pathname: '/login', 
        state: {from: props.location}
      };

      auth.clearAuthentication();

      return (
        <Redirect to={to} />
      );
    };

    return (
      <Route {...rest} render={props => {
        return renderRoute(props, rest);
      }}/>
    );
  }
}
