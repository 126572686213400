import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import http from '../../util/http';
import { withNotification } from '../notification/Notification';

class ChangePassword extends Component {
    static propTypes = {
      username: PropTypes.string.isRequired,
      open: PropTypes.bool,
      close: PropTypes.func
    }
    constructor(props) {
      super(props);
      this.state = {
        oldPassword: '',
        newPassword: '',
        error: '',
        success: ''
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }

    handleChange(event) {
      const name = event.target.name;
      this.setState({
        [name]: event.target.value,
        error: ''
      });
    }

    handleClose() {
      this.setState({
          oldPassword: '',
          newPassword: ''
      });
      this.props.close();
    }

    async handleSubmit(event) {
      event.preventDefault();

      try {

        const response =
                        await http.authorizedPost(process.env.REACT_APP_URL + '/auth/change-password')
                          .send({'user': {'username': this.props.username, 'oldPassword': this.state.oldPassword, 'newPassword': this.state.newPassword}});
        if (response.status === 200) {
            this.props.setNotificationMessage('Паролата е сменена успешно!');
            this.props.handleClose();
        }
      } catch (err) {
        if (err.status > 299 || err.status < 200) {
          this.setState({
            error: err.response.body.message,
            success: ''
          });
        }
      }
    }

    render() {
      return (
        <Dialog
            open={this.props.open}
            onClose={this.handleClose}
        >
        <DialogContent>
        <form id="changePasswordForm" onSubmit={this.handleSubmit}>
            <TextField
              autoFocus
              fullWidth
              required
              value={this.state.oldPassword}
              onChange={this.handleChange}
              name="oldPassword"
              margin="dense"
              label="Текуща парола"
              type="password"
            />
            <TextField
              fullWidth
              required
              value={this.state.newPassword}
              onChange={this.handleChange}
              name="newPassword"
              margin="dense"
              label="Нова парола"
              type="password"
            />
            <Typography color="error" >
                {this.state.error}
            </Typography>
        </form>
        </DialogContent>
        <DialogActions>
        <Button type="submit" variant="contained" color="primary" disabled={!this.state.oldPassword || !this.state.newPassword} onClick={this.handleSubmit}>
            Промени парола
          </Button>
          <Button variant="outlined" color="primary" onClick={this.handleClose}>
            Затвори
            </Button>
        </DialogActions>
        </Dialog>
      );
    }
}

export default withNotification(ChangePassword);
