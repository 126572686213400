import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';

import http from '../../../util/http';
import CustomModal from '../../customModal/CustomModal';

export default function Schedule(props) {

    const [rows, setRows] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [isChanged, setIsChanged] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
      async function fetchData() {
        try {
            const response =
                            await http.authorizedGet(process.env.REACT_APP_URL + "/traffic/hourlyOperatingSchedule");
            if (response.status === 200) {
                setColumns(formatColumns());
                let formattedRows = formatRows(response.body);
                setRows(formattedRows);
            } else if (response.status === 400 || response.status === 401) {
                alert(response.message);
            } else {
                alert('Сървърна грешка. Съобщете на администратора на системата.');
            }
        } catch (error) {
            console.error(error.message);
        }
      }
      fetchData();
    }, []);

    const formatRows = (data) => {
        let counter = 1;
        let dataArray = [];
        for (let key in data) {
            if (!data.hasOwnProperty(key)) continue; //if property is from prototype
            let dataRow = {};
            dataRow['id'] = counter++;
            dataRow['day'] = key === 'weekday' ? 'делник' : 'празник';
            for (let i=0; i<data[key].length; i++) {
                dataRow[`${i}`] = data[key][i] < 9 ? 'СП'+data[key][i] : (data[key][i] === 9 ? 'ЖМС' : 'ВТ');
            }
            dataArray.push(dataRow);
        }
        return dataArray;
    };

    const formatColumns = () => {
        let temp_columns = [
            { field: 'day', headerName: 'Ден', width: 100, editable: false, sortable: false, filterable: false },
          ];
        for (let i = 0; i < 24; i++) {
            temp_columns.push({field: `${i}`, headerName: `${i}`, width: 60, editable: props.role  === 0 ? true : false, sortable: false, filterable: false, type: 'singleSelect',
            valueOptions: ['CП1', 'CП2', 'CП3', 'CП4', 'CП5', 'CП6', 'CП7', 'CП8', 'ЖМС', 'ВТ'], })
        }
        return temp_columns;
    };

    const handleCellEditCommit = React.useCallback(
      ({ id, field, value }) => {
          const updatedRows = rows.map((row) => {
            if (row.id === id) {
              row[field] = value;
          }
            return row;
          });
          setRows(updatedRows);
          setIsChanged(true);
      },
      [rows],
    );

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const formatRowsForRequest = () => {
      let req_array = [];
      for (let key in rows) {
          for (let hour in rows[key]) {
            if (rows[key][hour] === 'ЖМС') {
              req_array.push(9);
            }
            else if (rows[key][hour] === 'ВТ') {
              req_array.push(10);
            }
            else if (parseInt(hour)) {
              req_array.push(parseInt(rows[key][hour][2]));
            }
          }
      }
      return req_array;
    };

    const sendSchedule = async () => {
      try {
        const schedule = formatRowsForRequest();
        const response = await http.authorizedPost(process.env.REACT_APP_URL + '/traffic/hourlyOperatingSchedule').send({schedule: schedule});
        if (response.status === 200) {
           handleClose();
           alert('Успешно запазен часови график');
        }
    } catch (error) {
        console.error(error.message);
    }
    };

  return (
    <div>
      { props.role === 0 && <Button variant="outlined" color="primary" disabled={!isChanged} onClick={handleOpen}>Запази</Button> }
      <div style={{ height: 260, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu={true}
          onCellEditCommit={handleCellEditCommit}
          hideFooter
          autoHeight
        />
        <CustomModal open={open} text='Сигурни ли сте, че искате да запазите този часови график?' execute={sendSchedule} close={handleClose} />
      </div>
    </div>
  );
}
