import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import http from '../../../util/http';

class ErrorsTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };

    this.clearErrors = this.clearErrors.bind(this);

  }

  async componentDidMount() {
    try {
      const response = await http.authorizedGet(process.env.REACT_APP_URL + "/traffic/controllerErrors");
      if (response.status === 200) {
        this.setState({
            errors: response.body,
        });
      } else if (response.status === 400 || response.status === 401) {
          alert(response.message);
      } else {
          alert('Сървърна грешка. Съобщете на администратора на системата.');
      }
  } catch (error) {
      console.error(error.message);
  }
  }

  async clearErrors() {
    try {
      const response =
                      await http.authorizedDelete(process.env.REACT_APP_URL + "/traffic/controllerErrors");
      if (response.status === 200) {
        this.setState({
            errors: [],
        });
        alert('Отказите са изчистени');
      } else if (response.status === 400 || response.status === 401) {
          alert(response.message);
      } else {
          alert('Сървърна грешка. Съобщете на администратора на системата.');
      }
  } catch (error) {
      console.error(error.message);
  }
  }

  render() {
    return (
        <div>
            <Button variant="outlined" color="primary" onClick={this.clearErrors}>
                Изчистване на откази
            </Button>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Отказ</TableCell>
                    <TableCell>Аргументи</TableCell>
                    <TableCell>Описание</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {this.state.errors.map((row, index) => (
                    <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell align="left">{row.title}</TableCell>
                    <TableCell align="left">{row.arguments}</TableCell>
                    <TableCell align="left">{row.description}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </div>
    );
  }
}

export default ErrorsTable;
