import React, {Component} from 'react';

import PrivateRoute from './components/privateRoute/PrivateRoute';
import Login from './components/login/Login.js';
import Home from './components/home/Home.js';

import {HashRouter as Router, Switch} from 'react-router-dom';
import { Route, Redirect } from 'react-router';
import {createHashHistory} from 'history';

import http from './util/http';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.customHistory = createHashHistory();
    http.history = this.customHistory;
  }

  render() {
    return (
      <div style={ {backgroundImage: 'url("/background.jpg")', backgroundSize: "cover", minHeight: "100vh"}}>
        <Router>
          <Switch>
            <PrivateRoute path="/app" component={Home} />
            <Route exact path="/login" component={Login} />
            <Redirect exact path="/" to="/app" />
          </Switch>
        </Router>
      </div>
    );
  }
}
