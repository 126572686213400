import React, { Component } from 'react';
import { Snackbar } from '@material-ui/core';

export const withNotification = WrappedComponent => class NotificationWrapper extends Component {
  constructor() {
    super();
    this.state = { message: '', open: false, duration: 5000 };
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
    this.setMessage = this.setMessage.bind(this);
  }

  setMessage(message, customDuration) {
    const duration = customDuration || 5000;
    this.setState({ message, open: true, duration });
  }

  handleCloseSnackbar() {
    this.setState({message: '', open: false});
  }

  render() {
    return (
      <div>
        <WrappedComponent {...this.props} setNotificationMessage={this.setMessage} />
        <Snackbar
          open={this.state.open}
          autoHideDuration={this.state.duration}
          onClose={this.handleCloseSnackbar}
          message={<span>{this.state.message}</span>}
        />
      </div>
    );
  }
};
