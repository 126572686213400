import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';

import CustomModal from '../../customModal/CustomModal';
import http from '../../../util/http';


const validateTime = (time) => {
  console.log(time);
  if (time >= 8 && time <= 60) {
    return true;
  }
  return false;
};

const columns = [
    { field: 'SP', headerName: 'Сигнални програми', width: 230, editable: false},
    { 
      field: 'F1', 
      headerName: 'Фаза 1', 
      width: 130, 
      editable: true, 
      preProcessEditCellProps: (params) => {
        const isValid = validateTime(params.props.value);
        return { ...params.props, error: !isValid };
      },
    },
    { 
      field: 'F2',
      headerName: 'Фаза 2',
      width: 130,
      editable: true,
      preProcessEditCellProps: (params) => {
        const isValid = validateTime(params.props.value);
        return { ...params.props, error: !isValid };
      },
    },
    { 
      field: 'F3',
      headerName: 'Фаза 3',
      width: 130,
      editable: true,
      preProcessEditCellProps: (params) => {
        const isValid = validateTime(params.props.value);
        return { ...params.props, error: !isValid };
      },
    }
  ];

export default function PhasesTime(props) {

    const [rows, setRows] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [isChanged, setIsChanged] = React.useState(false);

    useEffect(() => {
      async function fetchData() {
        try {
            const response =
                            await http.authorizedGet(process.env.REACT_APP_URL + "/traffic/phasesTime");
            if (response.status === 200) {
                let formatedRows = formatRows(response.body);
                setRows(formatedRows);
            } else if (response.status === 400 || response.status === 401) {
                alert(response.message);
            } else {
                alert('Сървърна грешка. Съобщете на администратора на системата.');
            }
        } catch (error) {
            console.error(error.message);
        }
      }
      fetchData();
    }, []);


  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
        const updatedRows = rows.map((row) => {
          if (row.id === id) {
            row[field] = value;
        }
          return row;
        });
        setRows(updatedRows);
        setIsChanged(true);
    },
    [rows],
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formatRows = (data) => {
      let counter = 1;
      let dataArray = [];
      for (let key in data) {
          if (!data.hasOwnProperty(key)) continue; //if property is from prototype
          let dataRow = {};
          dataRow['id'] = counter++;
          dataRow['SP'] = key;
          for (let i=0; i<Object.keys(data).length; i++) {
              dataRow['F'+(i+1)] = data[key][i];
          }
          dataArray.push(dataRow);
      }
      return dataArray;
  };

  const formatRequest = () => {
    let req_array = [];
    for (let elem in rows) {
        for (let time in rows[elem]) {
          if (parseInt(time.charAt(1))) {
            req_array.push(rows[elem][time]);
          }
        }
    }
    return req_array;
};

  const savePhasesTime = async () => {
    try {
      const phases_time = formatRequest();
      const response = await http.authorizedPost(process.env.REACT_APP_URL + '/traffic/phasesTime').send({phases_time: phases_time});
      if (response.status === 200) {
         handleClose();
         alert('Успешно запазени времена на фази');
      }
  } catch (error) {
      console.error(error.message);
  }
  };

  return (
    <div>
      { props.role === 0 && <Button variant="outlined" color="primary" disabled={!isChanged} onClick={handleOpen}>Запази</Button> }
      <div style={{ height: 480, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          onCellEditCommit={handleCellEditCommit}
          hideFooter
          autoHeight
        />
        <CustomModal open={open} text='Сигурни ли сте, че искате да запазите тези времена на фази?' execute={savePhasesTime} close={handleClose} />
      </div>
    </div>
  );
}

