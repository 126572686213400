import request from 'superagent';

import auth from './auth';

export default {
  history: null,

  _unauthorizedRedirect(req) {
    req.on('response', (res) => {
      if (res.status === 401) {
        if (this.history && this.history.hasOwnProperty('push')) {
          this.history.push('/login');
        }
        auth.clearAuthentication();
      }
    });
  },

  authorizedGet(route) {
    return request
      .get(route)
      .withCredentials()
      .use(this._unauthorizedRedirect.bind(this));
  },

  authorizedPost(route) {
    return request
      .post(route)
      .withCredentials()
      .use(this._unauthorizedRedirect.bind(this));      
  },

  authorizedPut(route) {
    return request
      .put(route)
      .withCredentials()
      .use(this._unauthorizedRedirect.bind(this));      
  },

  authorizedPatch(route) {
    return request
      .patch(route)
      .withCredentials()
      .use(this._unauthorizedRedirect.bind(this));      
  },

  authorizedDelete(route) {
    return request
      .delete(route)
      .withCredentials()
      .use(this._unauthorizedRedirect.bind(this));      
  }
};
