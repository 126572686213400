import React from 'react';

import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  
  function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  export default function CustomModal(props) {

    const classes = useStyles();

    const [modalStyle] = React.useState(getModalStyle);


  return (
    <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
    >
        <div style={modalStyle} className={classes.paper}>
        <p className="restartWarning"> {props.text} </p>
        <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <Button variant="contained" color="primary" onClick={props.execute}>
                Да
            </Button>
            <Button variant="outlined" color="primary" onClick={props.close}>
                Не
            </Button>
        </div>
        </div>
    </Modal>
  );
}
